.root {
  font-size: 0.875rem;
  color: var(--ambient-baseMinus25);
  cursor: pointer;
  text-align: center;
  line-height: 2.6rem;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.root:not(:first-child) {
  padding-left: 1.5rem;
}

.root:focus {
  outline: none;
}

.root:not(:last-child) {
  padding-right: 1.5rem;
}

.root:disabled {
  color: var(--ambient-basePlus75);
  cursor: inherit;
}

.root > div {
  box-sizing: border-box;
  border-bottom: solid transparent 0.25rem;
}

.root.active > div {
  border-bottom: solid var(--accent-basePlus25) 0.25rem;
}
