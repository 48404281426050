.itemsList {
  margin-top: 5px;
  box-shadow: var(--shadow30);
  max-height: 250px;
  overflow-y: auto;
  background-color: var(--color-white);
  font-size: 0.75rem;
}

.itemsList [data-reach-menu-item] {
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
}

.itemsList [data-reach-menu-item]:hover {
  background-color: var(--ambient-basePlus90);
}

.itemsList [data-reach-menu-item]:not(:last-child) {
  border-bottom: solid var(--ambient-basePlus90) 1px;
}

.invalidText {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}
