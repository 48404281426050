.table {
  width: 100%;
  position: relative;
  border-collapse: collapse;
  font-size: 0.75rem;
}

.cell {
  border-bottom: solid var(--ambient-basePlus75) 1px;
  padding: 1rem 0.5rem;
}

.row:first-child > .cell {
  border-top: solid var(--ambient-basePlus90) 1px;
}

.row.header > .cell {
  border-top: solid var(--ambient-basePlus90) 4px;
}

.cell.clickable,
.cell.sortable {
  cursor: pointer;
}

.sortArrow {
  position: relative;
  top: 4px;
  left: 10px;
  color: var(--accent-base);
}
