:root {
  --reach-menu-button: 1;
}

[data-reach-menu-item] {
  /* reach-menu-item */
  cursor: pointer;

  /* a */
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: initial;
}

/* pseudo pseudo selector */
[data-reach-menu-item][data-selected] {
  outline: none;
}

.item {
  width: 100%;
  cursor: pointer;
  background-color: white;
  font-size: 0.75rem;
  color: var(--accent-base);
  border: 0;
  text-align: left;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.item:hover {
  background-color: var(--accent-basePlus90);
}

.children {
  min-width: 7rem;
  padding: 0.375rem 1rem;
}
