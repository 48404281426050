.fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.container {
  display: inline-flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.radio {
  display: none;
}

.portSelectorItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.5rem;
  border-radius: 100%;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  cursor: pointer;
}

.portSelectorItem:hover {
  color: var(--color-white);
  background-color: var(--accent-base);
}

.portSelectorItem.disabled:hover {
  color: var(--ambient-basePlus75);
  background: initial;
}

.portSelectorItem.active {
  color: var(--color-white);
  background-color: var(--accent-base);
}

.portSelectorItem.disabled {
  color: var(--ambient-basePlus75);
  cursor: default;
}

.hasError {
  border-bottom: 1px solid var(--pink-base);
}

.invalidText {
  font-size: 0.75rem;
  color: var(--ambient-basePlus50);
}
