/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-menu-button: 1;
}

[data-reach-menu] {
  display: block;
  position: absolute;
}

[data-reach-menu-list] {
  display: block;
  white-space: nowrap;
  outline: none;
}

.button {
  color: var(--accent-basePlus25);
  background: none;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
}

.button > svg {
  display: block;
}

.menuList {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  min-width: 7rem;
}
