.root {
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  position: relative;
  user-select: none;
  padding: 0;
  margin: 0;
  align-items: center;
  display: inline-flex;
  width: auto;
  justify-content: center;
  background-color: transparent;
}

.small {
}

.medium {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.375rem 0;
}

.fullWidth {
  display: block;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.root.menu {
  display: inline-block;
  width: auto;
}

.icon,
.selectIcon,
.addIcon {
  margin: 0;
  fill: var(--accent-base);
}

.selectIcon {
  transform: rotate(90deg);
}

.addIcon,
.selectIcon {
  margin-right: 5px;
}

.root[disabled] {
  color: var(--ambient-basePlus75);
}

.icon[disabled],
.selectIcon[disabled],
.addIcon[disabled] {
  fill: var(--ambient-basePlus75);
}

.hasError {
  border-bottom: 1px solid;
  border-color: var(--pink-base);
}
