.button {
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  user-select: none;
  padding: 0;
  margin: 0;
  align-items: center;
  display: inline-flex;
  width: auto;
  min-width: 4rem;
  justify-content: center;
  border: 1px solid transparent;
}

.small {
  padding: 0.3125rem 0.625rem;
}

.medium {
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
}

.large {
  font-size: 1.125rem;
  line-height: 2;
  padding: 0.4375rem 1.625rem;
}

.fullWidth {
  display: block;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.solid {
  background-color: var(--accent-base);
  color: var(--color-white);
}

.solid:hover {
  background-color: var(--accent-baseMinus50);
}

.solid[disabled] {
  background-color: var(--ambient-basePlus75);
  opacity: 0.5;
}

.text {
  background-color: transparent;
  color: var(--accent-base);
}

.text:hover {
  color: var(--accent-baseMinus25);
}

.text[disabled] {
  color: var(--ambient-basePlus75);
}

.outlined {
  border: 1px solid var(--accent-base);
  background-color: transparent;
  color: var(--accent-base);
}

.outlined:hover {
  border: 1px solid var(--accent-baseMinus25);
  color: var(--accent-baseMinus25);
  background-color: transparent;
}

.outlined[disabled] {
  color: var(--ambient-basePlus75);
  border-color: var(--ambient-basePlus75);
}

.button[disabled] {
  cursor: inherit;
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 700% 0;
  }
}

.isLoading {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABQCAYAAACpv3NFAAADGklEQVR4AWIYSPD//39AsXah4ooORWGYcam767i7+/u/1F3XFxDOrp3+wMcKkZKZhF1dmce/j+GPN+3j0v+AVdgKffprsBX69Ndl7Z/09lpSPN/7Imv09V9P2LD0djw+Pfz0N0Dr+OnLJmiNLn5bsBX09G0j25bLskFf/22zYyne5xY6f5Uufjugbfr0t2QXtE4XvwyJLn4bkpWMZAH49c9IzknW0tuLGnfoc/+a5H6DvKVYn5EMffo7kgdt0sWvAFuhn/qKoF36+uek9I+ipbUT4yacnxg36/Tr/rJvKFC29HY8npbHn/qkDNqmi18FtkI/9VVBWfr6l6TmpGop1rd4G/Tr/toE6pbennd+iT79vNRBO/SHHnVpQOr0pz670gQV6OtfldY/mpZifYFZ19PFb1Na0p5Qy9LaM4/X6NMvSnuJOpZ/yqDFzzZDaNPFLytdDv/c35QeaJMufr2EvqW343EXz2/Sp1+RvgwsxfoC0fpgPIsWP9sIoU8Xv5wMQVX6+ndkBNqiP/Ed22bGlimjqebHuvTp12XPjC1jtsbbU8zPo8XPNkMY08WvIPugOn39+3IA2qZ/53MoB5ZifcF4IFo/wF/3y5EcWor1BaL1wXiRLn5Hcuy5RId08SvJMYd/4zOSE9AO/XXXqW3m1HIZxvTpt+XMnFqm+Bxvx+NpZfqHTidyLmfOnFuK9UXi9Sf0L73Kcg7q0Nf/QC4odPHblUvQAX36PblKuLQU75t/vqnQxe9CruTaMsHmBHxusP6CLn5VuQb16Ot/LDegXbr43YKO6dMfyN0v3Fp6OxyfUJUufjdyZ+4trZ0Yj8Trb+niV0ts+MFSrG/xhvT1P5MHUIb+je8j6Bz/0EOeQHW6+D3K8wSeLBNsjrWD8Ue6+DXkeUYvltaeZj3/qc+lvICy9A+dXkFX9OnvyxuoSRe/V3mfwpultWea/0oXv6a8Bz4sE2zORHwN/6nPjXyAcvQPnT5Bt/TpH8oXqE0Xvw/5nsGXpbfDcfMh6/TXXT//+LZMsDneToxHbO4Rff3v4z8wHp+Wrc2Rf/8fKrJDJf0nqJwAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-size: contain;
  background-origin: border-box;
  background-position: 0% 0%;
  animation: loading 20s linear infinite;
  pointer-events: none;
  opacity: 0.5;
}
