.root {
  border-radius: 50%;
  background-color: var(--blue-base);
  color: var(--color-white);
  font-size: 0.833em;
  text-align: center;
  text-overflow: ellipsis;
  display: inline-block;
  border: solid #fff 2px;
  box-sizing: content-box;
}

.root + .root {
  margin-left: -15px;
}

.root:nth-child(2n + 0) {
  background-color: var(--accent-basePlus25);
}

.root:nth-child(3n + 0) {
  background-color: var(--green-basePlus25);
}

.root.clickable {
  cursor: pointer;
}

.root.clickable:hover {
  border-color: var(--ambient-basePlus75);
}

.user {
  border-radius: 100%;
}

.group {
  border-radius: 25%;
}
