.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 4.6875rem;
  background-color: var(--ambient-base);
  flex-shrink: 0;
}

.root.expanded {
  width: 7.5rem;
}

.logo {
  height: var(--top-bar-menu-height);
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.logo > svg {
  position: relative;
  margin: 0 auto;
}

.logo > svg path[class='logo'] {
  color: var(--accent-base);
}

.logo > svg path[class='name'] {
  color: var(--color-white);
}

.expandButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: var(--color-white);
  background-color: var(--ambient-baseMinus10);
  height: 1.25rem;
}

.expandButton:focus {
  outline: none;
}

.expandButtonTitle {
  font-size: 0.625rem;
}

.expandButton > svg {
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 0.125rem;
  color: var(--accent-base);
}

.menuItems {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

/*
FIXME: This is a shortcut.
Obviously visibility must be controlled with props.
*/
.root .menuItems [data-title] {
  display: none;
}

.root.expanded .menuItems [data-title] {
  display: block;
}
