.modalContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  min-width: 35.0625rem;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.infoContainer {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.actionsContainer {
  box-sizing: border-box;
  background-color: var(--shade-05);
  width: 100%;
  height: 4.375rem;
  padding: 1.25rem 3.125rem;
}

.actionsPadding {
  text-align: right;
}
