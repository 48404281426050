.root {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  color: var(--color-white);
  font-size: 0.625rem;
  box-sizing: border-box;
  border-left: 5px solid transparent;
  padding: 1rem 0.3125rem 1rem 0;
}

.root.highlighted {
  background-color: var(--ambient-baseMinus10);
}

.root:hover,
.root.active {
  background-color: var(--accent-baseMinus50);
}

.active {
  border-left-color: var(--accent-baseMinus10);
  color: var(--accent-basePlus25);
}

.root [data-title] {
  margin-top: 0.5rem;
}

.title {
  color: var(--medium-base);
}
