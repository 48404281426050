.root {
  display: inline-block;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  line-height: 1.5;
  font-size: 0.75rem;
  position: relative;
}

.required {
  padding-right: 0.5rem;
}

.sup {
  position: absolute;
  padding-left: 0.25rem;
}
