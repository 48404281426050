.root {
}

.menuButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--top-bar-menu-height);
  border: none;
  background-color: var(--ambient-baseMinus25);
  color: var(--color-white);
  cursor: pointer;
  padding: 0 1rem;
  outline: none;
  font-size: 0.75rem;
}

.menuButton > .expandIcon {
  transform: rotate(90deg);
  margin-left: 1rem;
  color: var(--accent-base);
}

.menuButton:hover,
.menuButton[aria-expanded='true'] {
  background-color: var(--ambient-baseMinus10);
}

.menuList {
  display: block;
  width: auto;
  white-space: nowrap;
  background-color: var(--ambient-baseMinus10);
  outline: none;
  max-height: 50vh;
  overflow: scroll;
}

.menuList [data-reach-menu-item] {
  cursor: pointer;
  display: block;
  color: var(--accent-base);
  padding: 0.5rem 2rem;
  outline: none;
  font-size: 0.75rem;
}

.menuList [data-reach-menu-item]:not(:last-child) {
  border-bottom: solid var(--ambient-basePlus25) 1px;
}

.menuList [data-reach-menu-item][data-selected] {
  background-color: var(--ambient-base);
}

.title {
  padding-left: 0.5rem;
}
