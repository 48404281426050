.root {
  border: 3px solid;
  border-radius: 50%;
  animation: spin 575ms infinite linear;
  -o-animation: spin 575ms infinite linear;
  -ms-animation: spin 575ms infinite linear;
  -webkit-animation: spin 575ms infinite linear;
  -moz-animation: spin 575ms infinite linear;
}

.root.default {
  border-color: var(--accent-base);
  border-top-color: transparent;
}

.root.secondary {
  border-color: var(--ambient-basePlus50);
  border-top-color: transparent;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
