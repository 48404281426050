.actionsContainer {
  background-color: var(--shade-05);
  width: 100%;
  height: 4.375rem;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  height: inherit;
}
