.root {
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: space-between;
  background-color: var(--ambient-baseMinus25);
  width: 100%;
  height: var(--top-bar-menu-height);
  margin: 0;
  padding: 0 var(--container-gutters);
  color: var(--color-white);
}

.root > div {
  display: flex;
  flex-direction: row;
}
